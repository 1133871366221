import React from "react";

const SupplierForm = ({ addNewSupplier, q, updateSearch, errorSubmit }) => (
  <form
    className="mt-5 mb-5"
    style={{ display: "flex" }}
    onSubmit={(e) => addNewSupplier(e)}
  >
    <div className="field">
      <div className="control has-icons-right">
        <input
          className={`input ${errorSubmit && !q && "is-danger"}`}
          type="text"
          placeholder="search or add"
          name="q"
          value={q}
          onChange={(e) => updateSearch(e.target.value)}
        />
        {errorSubmit && !q && (
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        )}
      </div>
      {errorSubmit && !q && <p className="help is-danger">{errorSubmit}</p>}
    </div>
    <button className="button ml-2">add new</button>
  </form>
);
export default SupplierForm;
