import React, { useEffect, useState } from "react";
import axios from "axios";

const SkillMatrix = () => {
  const [matrix, updateMatrix] = useState(null);
  const [suppliers, updateSuppliers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/matrix"
        );
        const suppliers = await axios.get(
          process.env.REACT_APP_API_URL + "/suppliers"
        );
        updateMatrix(response.data);
        updateSuppliers(suppliers.data);
        console.log(response.data, suppliers.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (!matrix) fetchData();
  });

  const hasSkill = (employeeId, supplierId) => {
    const employee = matrix.find((emp) => emp._id === employeeId);
    let output = "";
    if (employee.skillsMatrix.length > 0) {
      console.log(employee.skillsMatrix, supplierId);
      const skillFound = employee.skillsMatrix.find(
        (skill) => skill.supplier._id === supplierId
      );
      if (skillFound) output = skillFound.level;
      console.log(skillFound);
    }

    return output;
  };

  return (
    <div>
      <h1 className="title">Matrix CSV</h1>
      {suppliers && (
        <p>Employees,{suppliers.map((sup) => `${sup.Supplier},`)}</p>
      )}
      {matrix &&
        matrix.map((employee, idx) => (
          <p>
            {employee["Full name"]},
            {suppliers &&
              suppliers.map((sup) => (
                <i>{`${hasSkill(employee._id, sup._id)},`}</i>
              ))}
          </p>
        ))}
    </div>
  );
};
export default SkillMatrix;
