import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import axios from "axios";
import Home from "./components/Home";
import Employee from "./components/Employee";
import Asset from "./components/Asset";
import SkillMatrix from "./components/SkillMatrix";
import SkillMatrixCSV from "./components/SkillMatrixCSV";
import ThankYou from "./components/ThankYou";
import AssetsEmployee from "./components/AssetsEmployee";

const App = () => {
  const [user, updateUser] = useState(null);
  const [loginError, updateLoginError] = useState(null);

  const saveUser = async (newUser, history) => {
    if (!loginError) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/employee",
          newUser
        );
        // console.log(response.data);
        updateUser(response.data);
        history.push(`/employee/${response.data._id}`);
      } catch (err) {
        console.log(err);
        updateLoginError(err);
      }
    }
  };

  return (
    <div className="section">
      <Link to="/">
        <h1 className="title is-4 mb-1">KYC AVC UK</h1>
      </Link>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Home
              {...props}
              saveUser={saveUser}
              user={user}
              updateLoginError={updateLoginError}
              loginError={loginError}
            />
          )}
        />
        <Route exact path="/employee/:eId" component={Employee} />
        <Route exact path="/assets/:eId" component={Asset} />
        <Route exact path="/matrix" component={SkillMatrix} />
        <Route exact path="/matrix/csv" component={SkillMatrixCSV} />
        <Route exact path="/thankyou" component={ThankYou} />
        <Route exact path="/assets-employees" component={AssetsEmployee} />
      </Switch>
    </div>
  );
};

export default App;
