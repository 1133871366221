import React from "react";
import { Link } from "react-router-dom";

const ListItem = ({ supplier, addSupplier }) => (
  <Link
    to="#"
    style={{ display: "flex" }}
    onClick={() => addSupplier(supplier)}
  >
    <h2 className="subtitle is-4">{supplier.Supplier.toLowerCase()}</h2>
    <i style={{ padding: "10px" }} className="fas fa-plus-circle"></i>
  </Link>
);
export default ListItem;
