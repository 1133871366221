import React from "react";
import { GoogleLogin } from "react-google-login";

const Home = ({ saveUser, history, updateLoginError, loginError }) => {
  const responseGoogle = (response) => {
    console.log(response);
    saveUser(response.profileObj, history);
  };

  const onLoginError = (response) => {
    console.log(response);
    updateLoginError(response);
    setTimeout(() => updateLoginError(null), 1500);
  };

  return (
    <div className="container">
      <div
        className="columns is-centered is-vcentered"
        style={{ height: "500px" }}
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
          buttonText="login using your company's account"
          onSuccess={responseGoogle}
          onFailure={onLoginError}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      {loginError && (
        <p className="help is-danger">error: {loginError.error}</p>
      )}
    </div>
  );
};
export default Home;
