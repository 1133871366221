import React, { useEffect, useState } from "react";
import axios from "axios";
import AssetForm from "./AssetForm";
import SelectedAssets from "./SelectedAssets";

const Asset = ({ match, history }) => {
  const [employee, updateEmployee] = useState(null);
  const [showYesNo, updateYesNo] = useState(true);
  const [showForm, updateShowForm] = useState(false);
  const [errorSubmit, updateErrorSubmit] = useState(false);
  const [assetModel, updateAssetModel] = useState("");
  const [typeOfAsset, updateTypeOfAsset] = useState("select one");
  const [description, updateDescription] = useState("");
  const [selectedAssets, updateSelectedAssets] = useState([]);
  const { eId } = match.params;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/employee/" + eId
        );
        const employeeObj = response.data;
        console.log(employeeObj);

        if (employeeObj.assets && employeeObj.assets.length > 0) {
          const translatedAssets = employeeObj.assets.map((asset) => {
            return {
              assetModel: asset["Asset Model"],
              typeOfAsset: asset["Type of Asset"],
              description: asset.Description,
            };
          });
          updateSelectedAssets(translatedAssets);
        }

        updateEmployee(employeeObj);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [eId]);

  const getFirstName = () => employee["Full name"].split(" ")[0];

  const yesNo = (what = false) => {
    console.log(what);
    if (what) {
      updateYesNo(!showYesNo);
      updateShowForm(true);
    } else {
      history.push({
        pathname: "/thankyou",
        state: { name: employee["Full name"] },
      });
    }
  };

  const saveAssets = async (e) => {
    console.log(e);
    e.preventDefault();

    console.log(selectedAssets);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/assets/" + eId,
        { selectedAssets }
      );
      console.log(response.data);
      history.push({
        pathname: "/thankyou",
        state: { name: employee["Full name"] },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearForm = () => {
    updateAssetModel("");
    updateTypeOfAsset(false);
    updateDescription("");
  };

  const addNewAsset = () => {
    if (assetModel && typeOfAsset !== "select one" && description) {
      const assetsCopy = [...selectedAssets];
      const asset = { assetModel, typeOfAsset, description };
      console.log(asset);
      assetsCopy.push(asset);
      updateSelectedAssets(assetsCopy);
      clearForm();
    } else {
      updateErrorSubmit("please add model asset, type and description");
      setTimeout(() => updateErrorSubmit(null), 1500);
    }
  };

  const deleteAsset = (assetToDelete) => {
    const filteredAssets = selectedAssets.filter(
      (asset) => assetToDelete.assetModel !== asset.assetModel
    );
    updateSelectedAssets(filteredAssets);
  };

  return (
    <div className="container is-fluid mt-5">
      {employee && (
        <div style={{ width: "60%" }}>
          <h1 className="title is-3">
            Hey {getFirstName()}, one more thing before you go!
          </h1>
          <p className="content mb-2">
            We need to understand better how you're going to access confidential
            data when you're not at the office or working remote when using{" "}
            <strong>personal</strong> assets/devices.
          </p>
          <p className="content mb-2">
            <strong>Do you want register personal device(s) ?</strong>
          </p>
        </div>
      )}
      {showYesNo && (
        <div className="pt-5">
          <button className="button" onClick={() => yesNo(true)}>
            Yes, please
          </button>
          <button className="button ml-2" onClick={() => yesNo()}>
            No, thanks
          </button>
        </div>
      )}
      {showForm && (
        <AssetForm
          assetModel={assetModel}
          typeOfAsset={typeOfAsset}
          description={description}
          addNewAsset={addNewAsset}
          updateAssetModel={updateAssetModel}
          updateTypeOfAsset={updateTypeOfAsset}
          updateDescription={updateDescription}
          errorSubmit={errorSubmit}
        />
      )}

      {selectedAssets.length > 0 && !showYesNo && (
        <SelectedAssets
          selectedAssets={selectedAssets}
          saveAssets={saveAssets}
          deleteAsset={deleteAsset}
        />
      )}
    </div>
  );
};

export default Asset;
