import React, { useEffect, useState } from "react";
import axios from "axios";
import ListItem from "./ListItem";
import SelectedSuppliers from "./SelectedSuppliers";
import SupplierForm from "./SupplierForm";

const Employee = ({ match, history }) => {
  const [employee, updateEmployee] = useState(null);
  const [q, updateSearch] = useState("");
  const [suppliers, updateSuppliers] = useState(null);
  const [selectedSuppliers, updateSelected] = useState([]);
  const [errorSubmit, updateErrorSubmit] = useState("");
  const [errorNoSkill, updateErrorNoSkill] = useState(null);
  const [hasSkillsFromDB, updateHasSkillFromDB] = useState(false);

  useEffect(() => {
    const { eId } = match.params;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/employee/" + eId
        );
        const employeeObj = response.data;
        console.log(employeeObj);

        // checking if employee already has a skillsMatrix array
        // to populate selectedSuppliers format
        updateEmployee(response.data);

        if ((employeeObj.skillsMatrix.length > 0) & !hasSkillsFromDB) {
          const selectedSuppliersFromDB = employeeObj.skillsMatrix.map(
            (emp) => {
              return { ...emp.supplier, level: emp.level };
            }
          );

          updateSelected(selectedSuppliersFromDB);
          updateHasSkillFromDB(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    if (!suppliers) fetchSuppliers();
  }, [match.params, suppliers]);

  async function fetchSuppliers() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/suppliers"
      );

      let suppliersFromDB = response.data;
      updateSuppliers(suppliersFromDB);
    } catch (err) {
      console.log(err);
    }
  }
  const addSupplier = (supplier) => {
    const copySelected = [...selectedSuppliers];
    let copySuppliers = [...suppliers];
    copySelected.push(supplier);
    copySuppliers = copySuppliers.filter((sup) => sup._id !== supplier._id);
    updateSelected(copySelected);
    updateSuppliers(copySuppliers);
    updateSearch("");
  };

  const deleteSupplier = (supplier) => {
    let copySelected = [...selectedSuppliers];
    copySelected = copySelected.filter((sup) => sup._id !== supplier._id);
    updateSelected(copySelected);
    fetchSuppliers();
  };

  const addNewSupplier = async (e) => {
    e.preventDefault();

    if (q) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/suppliers",
          {
            supplier: q,
            approvedBy: "Ryan Shaw",
          }
        );
        addSupplier(response.data);
        updateSearch("");
      } catch (err) {
        console.log(err);
      }
    } else {
      updateErrorSubmit("add a supplier, please");
      waitAndClearErrors();
    }
  };

  const saveSkills = async () => {
    const { eId } = match.params;

    const skillsMatrix = selectedSuppliers.map((sup) => {
      const skillLevel = sup.level;
      return { employee: eId, level: skillLevel, supplier: sup._id };
    });

    const hasError = errorNoSkillControl();

    if (!hasError) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/skills/" + eId,
          { skillsMatrix }
        );

        history.push({
          pathname: `/assets/${eId}`,
          state: { name: employee["Full name"] },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  function errorNoSkillControl() {
    const allHasSkills = selectedSuppliers.every((sup) => sup.level);
    console.log(allHasSkills);
    if (!allHasSkills) {
      updateErrorNoSkill("please choose a skill");
      waitAndClearErrors();
    }

    return !allHasSkills;
  }

  function waitAndClearErrors() {
    setTimeout(() => {
      updateErrorNoSkill(null);
      updateErrorSubmit(null);
    }, 2000);
  }

  const setSkill = (value, supId) => {
    let selectedCopy = [...selectedSuppliers];
    selectedCopy = selectedCopy.map((sup) => {
      if (sup._id === supId) sup.level = value;
      return sup;
    });
    updateSelected(selectedCopy);
  };

  return (
    <div className="container is-fluid mt-5">
      {employee && (
        <div style={{ width: "60%" }}>
          <h1 className="title is-3">Hey {employee["Full name"]}, welcome!</h1>
          <p className="content mb-2">
            In order to provide you access, checking licensing needs and create
            an automated way of granting access in the future we must know what
            services and softwares do you actively use in your day-to-day work.
          </p>
        </div>
      )}
      {selectedSuppliers.length > 0 && (
        <SelectedSuppliers
          selectedSuppliers={selectedSuppliers}
          deleteSupplier={deleteSupplier}
          saveSkills={saveSkills}
          errorNoSkill={errorNoSkill}
          setSkill={setSkill}
        />
      )}
      <SupplierForm
        addNewSupplier={addNewSupplier}
        q={q}
        updateSearch={updateSearch}
        errorSubmit={errorSubmit}
      />
      {suppliers ? (
        suppliers
          .filter((supplier) => {
            const selectedSuppliersIds = selectedSuppliers.map(
              (sup) => sup._id
            );
            if (selectedSuppliersIds.indexOf(supplier._id) >= 0) return false;

            if (q)
              return (
                supplier.Supplier.toLowerCase().indexOf(q.toLowerCase()) >= 0
              );

            return true;
          })
          .map((supplier, idx) => (
            <ListItem key={idx} supplier={supplier} addSupplier={addSupplier} />
          ))
      ) : (
        <p> no data </p>
      )}
    </div>
  );
};
export default Employee;
