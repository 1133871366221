import React from "react";

const ThankYou = ({ location }) => (
  <div className="container">
    <h1
      className="title is-1 columns is-centered is-vcentered mt-5"
      style={{ height: "600px" }}
    >
      thank you {location.state.name} and welcome!
    </h1>
  </div>
);

export default ThankYou;
