import React from "react";

const AssetForm = ({
  assetModel,
  typeOfAsset,
  description,
  addNewAsset,
  updateAssetModel,
  updateTypeOfAsset,
  updateDescription,
  errorSubmit,
}) => {
  return (
    <div className="mt-5 mb-5" style={{ width: "40%" }}>
      <div className="field">
        <label className="label">Asset Model</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Iphone, Macbook Pro, Pixel"
            name="assetModel"
            onChange={(e) => updateAssetModel(e.target.value)}
            value={assetModel}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Type of Asset</label>
        <div className="control">
          <div className="select">
            <select
              name="typeOfAsset"
              onChange={(e) => updateTypeOfAsset(e.target.value)}
              value={typeOfAsset}
            >
              <option>select one</option>
              <option>Phone</option>
              <option>Laptop</option>
              <option>Desktop</option>
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Description</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="i.e my main source of work"
            name="description"
            onChange={(e) => updateDescription(e.target.value)}
            value={description}
          />
        </div>
      </div>

      {/* <div className="field">
      <label className="label">Serial No</label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder="search or add"
          name="q"
        />
      </div>
    </div> */}
      <button className="button" onClick={() => addNewAsset()}>
        add new
      </button>
      {errorSubmit && <p className="help is-danger">{errorSubmit}</p>}
    </div>
  );
};

export default AssetForm;
