import React, { useEffect, useState } from "react";
import axios from "axios";

const SkillMatrix = () => {
  const [matrix, updateMatrix] = useState(null);
  const [suppliers, updateSuppliers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/matrix"
        );
        const suppliers = await axios.get(
          process.env.REACT_APP_API_URL + "/suppliers"
        );
        updateMatrix(response.data);
        updateSuppliers(suppliers.data);
        console.log(response.data, suppliers.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (!matrix) fetchData();
  });

  const hasSkill = (employeeId, supplierId) => {
    const employee = matrix.find((emp) => emp._id === employeeId);
    let output = "-";
    if (employee.skillsMatrix.length > 0) {
      console.log(employee.skillsMatrix, supplierId);
      const skillFound = employee.skillsMatrix.find(
        (skill) => skill.supplier._id === supplierId
      );
      if (skillFound) output = skillFound.level;
      console.log(skillFound);
    }

    return output;
  };
  // style={{ overflow: "scroll" }}
  return (
    <div className="container is-fluid" style={{ overflowX: "scroll" }}>
      <h1 className="title">Matrix</h1>
      <table className="table is-striped" style={{ margin: "20px" }}>
        <thead>
          <tr>
            <th>Employee</th>
            {suppliers &&
              suppliers.map((sup) => <th key={sup._id}>{sup.Supplier}</th>)}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {matrix &&
            matrix.map((employee, idx) => (
              <tr key={idx}>
                <td>{employee["Full name"].toLowerCase()}</td>
                {suppliers &&
                  suppliers.map((sup) => (
                    <td>
                      <b>{hasSkill(employee._id, sup._id)}</b>
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default SkillMatrix;
