import React, { useEffect, useState } from "react";
import axios from "axios";

const AssetsEmployee = () => {
  const [employees, updateEmployees] = useState(null);
  const [assets, updateAssets] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/employees"
        );
        const assets = await axios.get(
          process.env.REACT_APP_API_URL + "/assets"
        );
        updateEmployees(response.data);
        updateAssets(assets.data);
        console.log(response.data, assets.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (!employees) fetchData();
  });

  console.log(assets);
  return (
    <div className="container is-fluid" style={{ overflowX: "scroll" }}>
      <h1 className="title">Assets per Users</h1>
      <table className="table is-striped" style={{ margin: "20px" }}>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Ref.</th>
            <th>Asset Model</th>
            <th>Type of Asset</th>
            <th>Personal/Company</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {assets &&
            assets.map((asset, idx) => (
              <tr key={idx}>
                <td>
                  {asset.employee && asset.employee["Full name"].toLowerCase()}
                </td>
                <td>{asset._id}</td>
                <td>{asset["Asset Model"]}</td>
                <td>{asset["Type of Asset"]}</td>
                <td>{asset["Personal / Company"]}</td>
                <td>{asset.Description}</td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default AssetsEmployee;
