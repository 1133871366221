import React from "react";
import { Link } from "react-router-dom";

const SelectedAssets = ({ selectedAssets, deleteAsset, saveAssets }) => {
  return (
    <>
      <table className="table is-striped">
        <thead>
          <tr>
            <th>Model</th>
            <th>Type</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedAssets.map((asset, idx) => (
            <tr key={idx}>
              <td>{asset.assetModel} </td>
              <td>{asset.typeOfAsset}</td>
              <td>{asset.description}</td>
              <td>
                <Link to="#">
                  <i
                    className="fas fa-minus-circle"
                    onClick={() => deleteAsset(asset)}
                  ></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button is-warning" onClick={(e) => saveAssets(e)}>
        save
      </button>
    </>
  );
};

export default SelectedAssets;
