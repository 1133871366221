import React from "react";

const SkillCheck = ({ supId, setSkill, value, checked }) => {
  return (
    <td onClick={() => setSkill(value, supId)}>
      <span
        style={{ width: "15px", display: "inline-block", textAlign: "center" }}
      >
        {checked ? <i className="far fa-check-square"></i> : value}
      </span>
    </td>
  );
};

export default SkillCheck;
