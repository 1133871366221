import React from "react";
import { Link } from "react-router-dom";
import SkillCheck from "./SkillCheck";

const SelectedSuppliers = ({
  selectedSuppliers,
  deleteSupplier,
  saveSkills,
  errorNoSkill,
  skills,
  setSkill,
}) => {
  return (
    <>
      <table className="table is-striped">
        <thead>
          <tr>
            <th>Supplier Name</th>
            <th
              className="has-tooltip-multiline"
              colSpan="3"
              data-tooltip="1.Advanced - 2.Average - 3.Needs Improvements"
            >
              Your Skill <span className="fas fa-info-circle"></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedSuppliers.map((supplier, idx) => (
            <tr key={idx}>
              <td>{supplier.Supplier.toLowerCase()} </td>
              {[1, 2, 3].map((num) => (
                <SkillCheck
                  key={num}
                  value={num}
                  checked={supplier.level === num}
                  setSkill={setSkill}
                  supId={supplier._id}
                />
              ))}
              <td>
                <Link to="#">
                  <i
                    className="fas fa-minus-circle"
                    onClick={() => deleteSupplier(supplier)}
                  ></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button is-warning" onClick={() => saveSkills(skills)}>
        save
      </button>
      {errorNoSkill && <p className="help is-danger">{errorNoSkill}</p>}
    </>
  );
};

export default SelectedSuppliers;
